import { render, staticRenderFns } from "./Sao-Bento-do-Sul.vue?vue&type=template&id=26252bbe&scoped=true&"
import script from "./Sao-Bento-do-Sul.vue?vue&type=script&lang=js&"
export * from "./Sao-Bento-do-Sul.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26252bbe",
  null
  
)

export default component.exports